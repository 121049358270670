import { Color } from 'three';
import { Pass, PassName } from '../core';
/**
 * A pass that clears the input buffer or the screen.
 *
 * You can prevent specific bits from being cleared by setting either the
 * `autoClearColor`, `autoClearStencil` or `autoClearDepth` properties of the renderer
 * to `false`.
 */
export class ClearPass extends Pass {
    /**
     * Constructs a new clear pass.
     *
     * @param color - Determines whether the color buffer should be cleared.
     * @param depth - Determines whether the depth buffer should be cleared.
     * @param stencil - Determines whether the stencil buffer should be cleared.
     */
    constructor(color = true, depth = true, stencil = false) {
        super(PassName.Clear);
        this.color = color;
        this.depth = depth;
        this.stencil = stencil;
        /**
         * An override clear color.
         */
        this.overrideClearColor = null;
        /**
         * An override clear alpha.
         */
        this.overrideClearAlpha = 0.0;
        this.needsSwap = false;
    }
    /**
     * Clears the input buffer or the screen.
     *
     * @param renderer - The renderer.
     * @param inputBuffer - A frame buffer that contains the result of the previous pass.
     */
    render(renderer, inputBuffer) {
        const overrideClearColor = this.overrideClearColor;
        let clearAlpha;
        if (overrideClearColor !== null) {
            ClearPass.color.copy(renderer.getClearColor());
            clearAlpha = renderer.getClearAlpha();
            renderer.setClearColor(overrideClearColor, this.overrideClearAlpha);
        }
        renderer.setRenderTarget(this.renderToScreen ? null : inputBuffer);
        renderer.clear(this.color, this.depth, this.stencil);
        if (overrideClearColor !== null) {
            renderer.setClearColor(ClearPass.color, clearAlpha);
        }
    }
}
/** Used to save the original clear color of the renderer. */
ClearPass.color = new Color();
