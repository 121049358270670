import { BlendFunction } from '../../blending';
import { Effect, EffectName } from '../../core';
import fragment from './noise.frag';
export class NoiseEffect extends Effect {
    constructor({ blendFunction = BlendFunction.SCREEN, premultiply = false, } = {}) {
        super(EffectName.Noise, fragment, { blendFunction });
        this.premultiply = premultiply;
    }
    /**
     * Indicates whether the noise should be multiplied with the input color.
     */
    get premultiply() {
        return this.defines.has('PREMULTIPLY');
    }
    /**
     * Enables or disables noise premultiplication.
     * You'll need to call {@link EffectPass#recompile} after changing this value.
     */
    set premultiply(value) {
        value ? this.defines.set('PREMULTIPLY', '1') : this.defines.delete('PREMULTIPLY');
    }
}
