import { Uniform } from 'three';
import { BlendFunction } from '../../blending';
import { Effect, EffectName } from '../../core';
import fragment from './mirror.frag';
import vertex from './mirror.vert';
export class MirrorEffect extends Effect {
    constructor({ blendFunction = BlendFunction.NORMAL, side = 1, } = {}) {
        super(EffectName.Mirror, fragment, {
            blendFunction,
            uniforms: new Map([
                ['side', new Uniform(side)],
            ]),
            vertexShader: vertex,
        });
    }
}
