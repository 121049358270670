import { Uniform, Vector2 } from 'three';
import { BlendFunction } from '../../blending';
import { Effect, EffectName } from '../../core';
import fragment from './scanlines.frag';
export class ScanlineEffect extends Effect {
    constructor({ blendFunction = BlendFunction.OVERLAY, density = 1.25, } = {}) {
        super(EffectName.Scanline, fragment, {
            blendFunction,
            uniforms: new Map([
                ['count', new Uniform(0.0)],
            ]),
        });
        this.resolution = new Vector2();
        this.density = density;
    }
    /**
     * Returns the current scanline density.
     */
    getDensity() {
        return this.density;
    }
    /**
     * Sets the scanline density.
     */
    setDensity(density) {
        this.density = density;
        this.setSize(this.resolution.x, this.resolution.y);
    }
    setSize(width, height) {
        this.resolution.set(width, height);
        this.uniforms.get('count').value = Math.round(height * this.density);
    }
}
