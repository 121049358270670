import { Uniform } from 'three';
import { BlendFunction } from '../../blending';
import { Effect, EffectAttribute, EffectName } from '../../core';
import fragment from './bokeh.frag';
/**
 * A depth of field (bokeh) effect.
 *
 * Original shader code by Martins Upitis:
 *  http://artmartinsh.blogspot.com/2010/02/glsl-lens-blur-filter-with-bokeh.html
 */
export class BokehEffect extends Effect {
    constructor({ blendFunction = BlendFunction.NORMAL, focus = 0.5, dof = 0.02, aperture = 0.015, maxBlur = 1.0, } = {}) {
        super(EffectName.Bokeh, fragment, {
            attributes: EffectAttribute.CONVOLUTION | EffectAttribute.DEPTH,
            blendFunction,
            uniforms: new Map([
                ['focus', new Uniform(focus)],
                ['dof', new Uniform(dof)],
                ['aperture', new Uniform(aperture)],
                ['maxBlur', new Uniform(maxBlur)],
            ]),
        });
    }
}
