/**
 * A blend function enumeration.
 */
export var BlendFunction;
(function (BlendFunction) {
    /** No blending. The effect will not be included in the final shader. */
    BlendFunction[BlendFunction["SKIP"] = 0] = "SKIP";
    /** Additive blending. Fast, but may produce washed out results. */
    BlendFunction[BlendFunction["ADD"] = 1] = "ADD";
    /** Alpha blending. Blends based on the alpha value of the new color. Opacity will be ignored. */
    BlendFunction[BlendFunction["ALPHA"] = 2] = "ALPHA";
    /** Average blending. */
    BlendFunction[BlendFunction["AVERAGE"] = 3] = "AVERAGE";
    /** Color dodge. */
    BlendFunction[BlendFunction["COLOR_BURN"] = 4] = "COLOR_BURN";
    /** Color burn. */
    BlendFunction[BlendFunction["COLOR_DODGE"] = 5] = "COLOR_DODGE";
    /** Prioritize darker colors. */
    BlendFunction[BlendFunction["DARKEN"] = 6] = "DARKEN";
    /** Color difference. */
    BlendFunction[BlendFunction["DIFFERENCE"] = 7] = "DIFFERENCE";
    /** Color exclusion. */
    BlendFunction[BlendFunction["EXCLUSION"] = 8] = "EXCLUSION";
    /** Prioritize lighter colors. */
    BlendFunction[BlendFunction["LIGHTEN"] = 9] = "LIGHTEN";
    /** Color multiplication. */
    BlendFunction[BlendFunction["MULTIPLY"] = 10] = "MULTIPLY";
    /** Color division. */
    BlendFunction[BlendFunction["DIVIDE"] = 11] = "DIVIDE";
    /** Color negation. */
    BlendFunction[BlendFunction["NEGATION"] = 12] = "NEGATION";
    /** Normal blending. The new color overwrites the old one. */
    BlendFunction[BlendFunction["NORMAL"] = 13] = "NORMAL";
    /** Color overlay. */
    BlendFunction[BlendFunction["OVERLAY"] = 14] = "OVERLAY";
    /** Color reflection. */
    BlendFunction[BlendFunction["REFLECT"] = 15] = "REFLECT";
    /** Screen blending. The two colors are effectively projected on a white screen simultaneously. */
    BlendFunction[BlendFunction["SCREEN"] = 16] = "SCREEN";
    /** Soft light blending. */
    BlendFunction[BlendFunction["SOFT_LIGHT"] = 17] = "SOFT_LIGHT";
    /** Color subtraction. */
    BlendFunction[BlendFunction["SUBTRACT"] = 18] = "SUBTRACT";
})(BlendFunction || (BlendFunction = {}));
