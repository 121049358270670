import { Uniform } from 'three';
import { BlendFunction } from '../../blending';
import { Effect, EffectName } from '../../core';
import fragment from './sepia.frag';
/**
 * A sepia effect.
 */
export class SepiaEffect extends Effect {
    constructor({ blendFunction = BlendFunction.NORMAL, intensity = 1.0, } = {}) {
        super(EffectName.Sepia, fragment, {
            blendFunction,
            uniforms: new Map([
                ['intensity', new Uniform(intensity)],
            ]),
        });
    }
}
