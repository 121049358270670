import { Uniform, Vector2, Vector3 } from 'three';
import { Effect, EffectName } from '../../core';
import fragment from './shock-wave.frag';
import vertex from './shock-wave.vert';
const HALF_PI = Math.PI * 0.5;
const v = new Vector3();
const ab = new Vector3();
/**
 * A shock wave effect.
 *
 * Warning! This effect cannot be merged with convolution effects.
 *
 * Based on a Gist by Jean-Philippe Sarda: https://gist.github.com/jpsarda/33cea67a9f2ecb0a0eda
 */
export class ShockWaveEffect extends Effect {
    /**
     * Constructs a new shock wave effect.
     *
     * @param camera - The main camera.
     * @param epicenter - The world position of the shock wave epicenter.
     * @param options - The options.
     */
    constructor(camera, epicenter = new Vector3(), { speed = 2.0, maxRadius = 1.0, waveSize = 0.2, amplitude = 0.05, } = {}) {
        super(EffectName.ShockWave, fragment, {
            uniforms: new Map([
                ['active', new Uniform(false)],
                ['center', new Uniform(new Vector2(0.5, 0.5))],
                ['cameraDistance', new Uniform(1.0)],
                ['size', new Uniform(1.0)],
                ['radius', new Uniform(-waveSize)],
                ['maxRadius', new Uniform(maxRadius)],
                ['waveSize', new Uniform(waveSize)],
                ['amplitude', new Uniform(amplitude)],
            ]),
            vertexShader: vertex,
        });
        this.camera = camera;
        this.epicenter = epicenter;
        /**
         * Time accumulator.
         */
        this.time = 0;
        /**
         * Indicates whether the shock wave animation is active.
         */
        this.active = false;
        this.screenPosition = this.uniforms.get('center').value;
        this.speed = speed;
    }
    /**
     * Emits the shock wave.
     */
    explode() {
        this.time = 0.0;
        this.active = true;
        this.uniforms.get('active').value = true;
    }
    update(renderer, inputBuffer, delta) {
        if (this.active) {
            const waveSize = this.uniforms.get('waveSize').value;
            // Calculate direction vectors.
            this.camera.getWorldDirection(v);
            ab.copy(this.camera.position).sub(this.epicenter);
            // Don't render the effect if the object is behind the camera.
            if (v.angleTo(ab) > HALF_PI) {
                // Scale the effect based on distance to the object.
                this.uniforms.get('cameraDistance').value = this.camera.position.distanceTo(this.epicenter);
                // Calculate the screen position of the epicenter.
                v.copy(this.epicenter).project(this.camera);
                this.screenPosition.set((v.x + 1.0) * 0.5, (v.y + 1.0) * 0.5);
            }
            // Update the shock wave radius based on time.
            this.time += delta * this.speed;
            const radius = this.time - waveSize;
            this.uniforms.get('radius').value = radius;
            if (radius >= (this.uniforms.get('maxRadius').value + waveSize) * 2.0) {
                this.active = false;
                this.uniforms.get('active').value = false;
            }
        }
    }
}
