import { ClearPass } from './ClearPass';
import { Pass, PassName } from '../core';
/**
 * A pass that renders a given scene into the input buffer or to screen.
 * This pass uses a {@link ClearPass} to clear the target buffer.
 */
export class RenderPass extends Pass {
    /**
     * Constructs a new render pass.
     *
     * @param scene The scene to render.
     * @param camera The camera to use to render the scene.
     * @param options Additional options.
     */
    constructor(scene, camera, overrideMaterial = null) {
        super(PassName.Render, scene, camera);
        this.scene = scene;
        this.camera = camera;
        this.overrideMaterial = overrideMaterial;
        this.needsSwap = false;
        this.clearPass = new ClearPass();
        this.depthTexture = null;
    }
    /**
     * Indicates whether the target buffer should be cleared before rendering.
     */
    get clear() {
        return this.clearPass.enabled;
    }
    /**
     * Enables or disables auto clear.
     */
    set clear(value) {
        this.clearPass.enabled = value;
    }
    /**
     * Returns the clear pass.
     */
    getClearPass() {
        return this.clearPass;
    }
    /**
     * Returns the current depth texture.
     */
    getDepthTexture() {
        return this.depthTexture;
    }
    /**
     * Sets the depth texture.
     *
     * The provided texture will be attached to the input buffer unless this pass
     * renders to screen.
     */
    setDepthTexture(depthTexture, depthPacking = 0) {
        this.depthTexture = depthTexture;
    }
    /**
     * Renders the scene.
     *
     * @param renderer The renderer.
     * @param inputBuffer A frame buffer that contains the result of the previous pass.
     * @param outputBuffer - A frame buffer that serves as the output render target unless this pass renders to screen.
     */
    render(renderer, inputBuffer, outputBuffer) {
        const scene = this.scene;
        const overrideMaterial = scene.overrideMaterial;
        if (this.depthTexture !== null && !this.renderToScreen) {
            inputBuffer.depthTexture = this.depthTexture;
            outputBuffer.depthTexture = null;
        }
        if (this.clear) {
            this.clearPass.renderToScreen = this.renderToScreen;
            this.clearPass.render(renderer, inputBuffer);
        }
        scene.overrideMaterial = this.overrideMaterial;
        renderer.setRenderTarget(this.renderToScreen ? null : inputBuffer);
        renderer.render(scene, this.camera);
        scene.overrideMaterial = overrideMaterial;
    }
}
