import { ShaderMaterial, Uniform, Vector2 } from 'three';
import fragment from './color-edges.frag';
import vertex from './color-edges.vert';
/**
 * A material that detects edges in a color texture.
 * Mainly used for Subpixel Morphological Antialiasing.
 */
export class ColorEdgesMaterial extends ShaderMaterial {
    /**
     * Constructs a new color edges material.
     * @param texelSize - The absolute screen texel size.
     */
    constructor(texelSize = new Vector2()) {
        super({
            defines: {
                EDGE_THRESHOLD: '0.1',
            },
            uniforms: {
                inputBuffer: new Uniform(null),
                texelSize: new Uniform(texelSize),
            },
            fragmentShader: fragment,
            vertexShader: vertex,
            depthWrite: false,
            depthTest: false,
        });
    }
    /**
     * Sets the edge detection sensitivity.
     *
     * A lower value results in more edges being detected at the expense of
     * performance.
     *
     * 0.1 is a reasonable value, and allows to catch most visible edges.
     * 0.05 is a rather overkill value, that allows to catch 'em all.
     *
     * If temporal supersampling is used, 0.2 could be a reasonable value,
     * as low contrast edges are properly filtered by just 2x.
     *
     * @param threshold - The edge detection sensitivity. Range: [0.05, 0.5].
     */
    setEdgeDetectionThreshold(threshold) {
        this.defines.EDGE_THRESHOLD = threshold.toFixed(2);
        this.needsUpdate = true;
    }
}
