import { Color, MeshNormalMaterial, LinearFilter, RGBFormat, Vector2, WebGLRenderTarget, } from 'three';
import { Pass, PassName } from '../core';
import { RenderPass } from './RenderPass';
/**
 * A pass that renders the normals of a given scene.
 */
export class NormalPass extends Pass {
    /**
     * Constructs a new normal pass.
     *
     * @param scene - The scene to render.
     * @param camera - The camera to use to render the scene.
     * @param [options] - The options.
     */
    constructor(scene, camera, options = {}) {
        super(PassName.Normal, scene, camera);
        this.scene = scene;
        this.camera = camera;
        this.needsSwap = false;
        /** The original resolution */
        this.resolution = new Vector2();
        /** The current resolution scale. */
        this.resolutionScale = 0.5;
        this.renderPass = new RenderPass(scene, camera, {
            overrideMaterial: new MeshNormalMaterial({
                morphTargets: true,
                skinning: true,
            }),
            clearColor: new Color(0x7777ff),
            clearAlpha: 1.0,
        });
        const defaultRenderTarget = (() => {
            const target = new WebGLRenderTarget(1, 1, {
                minFilter: LinearFilter,
                magFilter: LinearFilter,
                format: RGBFormat,
            });
            target.texture.name = 'NormalPass.Target';
            target.texture.generateMipmaps = false;
            return target;
        })();
        this.renderTarget = !!options.renderTarget
            ? options.renderTarget
            : defaultRenderTarget;
        this.resolutionScale = (options.resolutionScale !== undefined)
            ? options.resolutionScale
            : 0.5;
    }
    /**
     * Returns the current resolution scale.
     */
    getResolutionScale() {
        return this.resolutionScale;
    }
    /**
     * Sets the resolution scale.
     */
    setResolutionScale(scale) {
        this.resolutionScale = scale;
        this.setSize(this.resolution.x, this.resolution.y);
    }
    /**
     * Renders the scene normals.
     *
     * @param renderer - The renderer.
     * @param inputBuffer - A frame buffer that contains the result of the previous pass.
     * @param outputBuffer - A frame buffer that serves as the output render target unless this pass renders to screen.
     * @param [delta] - The time between the last frame and the current one in seconds.
     * @param [stencilTest] - Indicates whether a stencil mask is active.
     */
    render(renderer, inputBuffer, outputBuffer, delta, stencilTest) {
        const renderTarget = this.renderToScreen ? null : this.renderTarget;
        this.renderPass.render(renderer, renderTarget, renderTarget);
    }
    /**
     * Updates the size of this pass.
     */
    setSize(width, height) {
        this.resolution.set(width, height);
        this.renderTarget.setSize(Math.max(1, Math.floor(width * this.resolutionScale)), Math.max(1, Math.floor(height * this.resolutionScale)));
    }
}
