import { DataTexture, FloatType, NearestFilter, RepeatWrapping, RGBFormat, Uniform, Vector2, EventDispatcher, } from 'three';
import { BlendFunction } from '../../blending';
import { Effect, EffectName } from '../../core';
import { randomFloat } from '../../utils';
import fragment from './glitch.frag';
export var GlitchMode;
(function (GlitchMode) {
    /** No glitches. */
    GlitchMode[GlitchMode["DISABLED"] = 0] = "DISABLED";
    /** Sporadic glitches. */
    GlitchMode[GlitchMode["SPORADIC"] = 1] = "SPORADIC";
    /** Constant mild glitches. */
    GlitchMode[GlitchMode["CONSTANT_MILD"] = 2] = "CONSTANT_MILD";
    /** Constant wild glitches. */
    GlitchMode[GlitchMode["CONSTANT_WILD"] = 3] = "CONSTANT_WILD";
})(GlitchMode || (GlitchMode = {}));
/**
 * A glitch effect. Can be used to influence the {@link ChromaticAberrationEffect}. *
 * Reference: https://github.com/staffantan/unityglitch
 * Warning: This effect cannot be merged with convolution effects.
 */
export class GlitchEffect extends Effect {
    constructor({ blendFunction = BlendFunction.NORMAL, chromaticAberrationOffset = null, delay = new Vector2(1.5, 3.5), duration = new Vector2(0.6, 1.0), strength = new Vector2(0.3, 1.0), columns = 0.05, ratio = 0.85, perturbationMap = null, dtSize = 64, } = {}) {
        super(EffectName.Glitch, fragment, {
            blendFunction,
            uniforms: new Map([
                ['perturbationMap', new Uniform(null)],
                ['columns', new Uniform(columns)],
                ['active', new Uniform(false)],
                ['random', new Uniform(0.02)],
                ['seed', new Uniform(new Vector2())],
                ['distortion', new Uniform(new Vector2())],
            ]),
        });
        this.chromaticAberrationOffset = chromaticAberrationOffset;
        this.delay = delay;
        this.dispatcher = new EventDispatcher();
        this.duration = duration;
        this.mode = GlitchMode.SPORADIC;
        this.ratio = ratio;
        this.strength = strength;
        this.time = 0;
        this.distortion = this.uniforms.get('distortion').value;
        this.seed = this.uniforms.get('seed').value;
        this.breakPoint = new Vector2(randomFloat(this.delay.x, this.delay.y), randomFloat(this.duration.x, this.duration.y));
        this.perturbationMap = perturbationMap === null
            ? this.generatePerturbationMap(dtSize)
            : perturbationMap;
        this.setPerturbationMap(this.perturbationMap);
        this.perturbationMap.generateMipmaps = false;
    }
    /**
     * Indicates whether the glitch effect is currently active.
     */
    get active() {
        return this.uniforms.get('active').value;
    }
    /**
     * Returns the current perturbation map.
     */
    getPerturbationMap() {
        return this.perturbationMap;
    }
    /**
     * Replaces the current perturbation map with the given one.
     * The current map will be disposed if it was generated by this effect.
     */
    setPerturbationMap(perturbationMap) {
        if (this.perturbationMap !== null && this.perturbationMap.name === GlitchEffect.generatedTexture) {
            this.perturbationMap.dispose();
        }
        perturbationMap.wrapS = perturbationMap.wrapT = RepeatWrapping;
        perturbationMap.magFilter = perturbationMap.minFilter = NearestFilter;
        this.perturbationMap = perturbationMap;
        this.uniforms.get('perturbationMap').value = perturbationMap;
    }
    generatePerturbationMap(size = 64) {
        const pixels = size * size;
        const data = new Float32Array(pixels * 3);
        let i;
        let x;
        for (i = 0; i < pixels; ++i) {
            x = Math.random();
            data[i * 3] = x;
            data[i * 3 + 1] = x;
            data[i * 3 + 2] = x;
        }
        const map = new DataTexture(data, size, size, RGBFormat, FloatType);
        map.name = GlitchEffect.generatedTexture;
        map.needsUpdate = true;
        return map;
    }
    update(renderer, inputBuffer, delta) {
        const mode = this.mode;
        const breakPoint = this.breakPoint;
        const offset = this.chromaticAberrationOffset;
        const s = this.strength;
        let time = this.time;
        let active = false;
        let r = 0.0;
        let a = 0.0;
        let trigger;
        if (mode !== GlitchMode.DISABLED) {
            if (mode === GlitchMode.SPORADIC) {
                time += delta;
                trigger = (time > breakPoint.x);
                if (time >= (breakPoint.x + breakPoint.y)) {
                    breakPoint.set(randomFloat(this.delay.x, this.delay.y), randomFloat(this.duration.x, this.duration.y));
                    time = 0;
                }
            }
            r = Math.random();
            this.uniforms.get('random').value = r;
            if ((trigger && r > this.ratio) || mode === GlitchMode.CONSTANT_WILD) {
                active = true;
                r *= s.y * 0.03;
                a = randomFloat(-Math.PI, Math.PI);
                this.seed.set(randomFloat(-s.y, s.y), randomFloat(-s.y, s.y));
                this.distortion.set(randomFloat(0.0, 1.0), randomFloat(0.0, 1.0));
            }
            else if (trigger || mode === GlitchMode.CONSTANT_MILD) {
                active = true;
                r *= s.x * 0.03;
                a = randomFloat(-Math.PI, Math.PI);
                this.seed.set(randomFloat(-s.x, s.x), randomFloat(-s.x, s.x));
                this.distortion.set(randomFloat(0.0, 1.0), randomFloat(0.0, 1.0));
            }
            this.time = time;
        }
        if (offset !== null) {
            if (active) {
                offset.set(Math.cos(a), Math.sin(a)).multiplyScalar(r);
            }
            else {
                offset.set(0.0, 0.0);
            }
        }
        if (active !== this.uniforms.get('active').value) {
            this.dispatcher.dispatchEvent.call(this, active ? { type: 'glitchstart' } : { type: 'glitchend' });
        }
        this.uniforms.get('active').value = active;
    }
    addEventListener(type, listener) {
        this.dispatcher.addEventListener.call(this, type, listener);
    }
    hasEventListener(type, listener) {
        return this.dispatcher.hasEventListener.call(this, type, listener);
    }
    removeEventListener(type, listener) {
        this.dispatcher.removeEventListener.call(this, type, listener);
    }
}
/**
 * A label for generated data textures.
 */
GlitchEffect.generatedTexture = 'Glitch.Generated';
