/**
 * An enumeration of effect attributes.
 * Attributes can be concatenated using the bitwise OR operator.
 *
 * @example
 * const attributes = EffectAttribute.CONVOLUTION | EffectAttribute.DEPTH;
 */
export var EffectAttribute;
(function (EffectAttribute) {
    /* No attributes. Most effects don't need to specify any attributes. */
    EffectAttribute[EffectAttribute["NONE"] = 0] = "NONE";
    /** Describes effects that require a depth texture. */
    EffectAttribute[EffectAttribute["DEPTH"] = 1] = "DEPTH";
    /**
     * Describes effects that fetch additional samples from the input buffer.
     * There cannot be more than one effect with this attribute per {@link EffectPass}.
     */
    EffectAttribute[EffectAttribute["CONVOLUTION"] = 2] = "CONVOLUTION";
})(EffectAttribute || (EffectAttribute = {}));
export var EffectName;
(function (EffectName) {
    EffectName["Bloom"] = "BloomEffect";
    EffectName["Bokeh"] = "BokehEffect";
    EffectName["ChromaticAberration"] = "ChromaticAberrationEffect";
    EffectName["ColorAverage"] = "ColorAverageEffect";
    EffectName["Glitch"] = "GlitchEffect";
    EffectName["Mirror"] = "MirrorEffect";
    EffectName["Noise"] = "NoiseEffect";
    EffectName["Scanline"] = "ScanlineEffect";
    EffectName["Sepia"] = "SepiaEffect";
    EffectName["ShockWave"] = "ShockWaveEffect";
    EffectName["SMAA"] = "SMAAEffect";
})(EffectName || (EffectName = {}));
export var PassName;
(function (PassName) {
    PassName["Blur"] = "blur";
    PassName["Clear"] = "clear";
    PassName["ClearMask"] = "clearMask";
    PassName["Effect"] = "effect";
    PassName["Mask"] = "mask";
    PassName["Normal"] = "normal";
    PassName["Render"] = "render";
    PassName["Save"] = "save";
    PassName["Shader"] = "shader";
})(PassName || (PassName = {}));
/**
 * An enumeration of shader code placeholders.
 */
export var Section;
(function (Section) {
    /**  A placeholder for function and variable declarations inside the fragment shader. */
    Section["FRAGMENT_HEAD"] = "FRAGMENT_HEAD";
    /**  A placeholder for UV transformations inside the fragment shader. */
    Section["FRAGMENT_MAIN_UV"] = "FRAGMENT_MAIN_UV";
    /**  A placeholder for color calculations inside the fragment shader. */
    Section["FRAGMENT_MAIN_IMAGE"] = "FRAGMENT_MAIN_IMAGE";
    /**  A placeholder for function and variable declarations inside the vertex shader. */
    Section["VERTEX_HEAD"] = "VERTEX_HEAD";
    /**  A placeholder for supporting calculations inside the vertex shader. */
    Section["VERTEX_MAIN_SUPPORT"] = "VERTEX_MAIN_SUPPORT";
})(Section || (Section = {}));
/** An enumeration of WebGL extensions. */
export var WebGLExtension;
(function (WebGLExtension) {
    /** Enables derivatives by adding the functions dFdx, dFdy and fwidth. */
    WebGLExtension["DERIVATIVES"] = "derivatives";
    /** Enables gl_FragDepthEXT to set a depth value of a fragment from within the fragment shader. */
    WebGLExtension["FRAG_DEPTH"] = "fragDepth";
    /** Enables multiple render targets (MRT) support. */
    WebGLExtension["DRAW_BUFFERS"] = "drawBuffers";
    /** Enables explicit control of texture LOD. */
    WebGLExtension["SHADER_TEXTURE_LOD"] = "shaderTextureLOD";
})(WebGLExtension || (WebGLExtension = {}));
